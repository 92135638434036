import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setAgentStatus } from "../../store/slice/agentSlice";
import { setLenderStatus } from "../../store/slice/lenderSlice";
import { useTranslation } from "react-i18next";

const AgentCustomModal = ({ onSave, children, navigatePath = null, id }) => {
  const { t } = useTranslation();
  const { agentOpen } = useSelector((state) => state.agent);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    navigatePath && navigate(navigatePath);
    dispatch(
      setAgentStatus({
        modalType: agentOpen.modalType,
        isOpen: false,
        data: agentOpen?.data,
      })
    )
   
  };

  const handleSave = async () => {
    await onSave();
    handleClose();
  };

  return (
    <Modal
  show={agentOpen.isOpen}
  onHide={handleClose}
  className="modal-dialog-centered modal-lg"
  aria-labelledby="exampleModalLabel"
  id={id}
  centered
>
  <div className="d-flex flex-column h-100">
    {/* Close Button */}
    <button
      type="button"
      className="btn-close pt-4 pe-4 align-self-end"
      onClick={handleClose}
    ></button>

    {/* Modal Body - Scrollable Content */}
    <Modal.Body className="modal-body p-0" style={{ maxHeight: "85vh", overflowY: "auto" }}>
      {children}
    </Modal.Body>
  </div>
</Modal>

  );
};

export default AgentCustomModal;